<template>
    <div class="client-clinical-attendance-history d-flex flex-column">
        <div class="clinical-counselors-header d-flex gap-2 align-items-center">
            <h3 class="flex-1">Mood History</h3>
            <div
                class="d-flex gap-4 bg-white align-items-center px-4 py-2 rounded-5"
                style="color: #979699; font-weight: 600; font-size: 1rem"
            >
                <span class="flex gap-2 align-items-center"
                    ><span class="color-indicator" style="background-color: #aac832"></span>Pleasant</span
                >
                <span class="flex gap-2 align-items-center"
                    ><span class="color-indicator" style="background-color: #cb177d"></span> Neutral</span
                >
                <span class="flex gap-2 align-items-center"
                    ><span class="color-indicator" style="background-color: #f58220"></span>Negative</span
                >
            </div>
            <div class="px-2">
                <DateRangeSelector
                    @vitalPeriodChangeHandler="handleHistoryPeriod"
                    :defaultVitalPeriod="selectedOption"
                />
            </div>
            <div class="m-2" ref="applicationDropdownContainer">
                <button
                    class="btn dropdown-btn"
                    @click="showApplicationDropdown = !showApplicationDropdown"
                    style="min-width: 10rem"
                >
                    {{ selectedApplication }} <span class="material-icons-outlined">expand_more</span>
                </button>
                <ul
                    v-if="showApplicationDropdown"
                    class="dropdown-container header-right-dropdown"
                    style="min-width: 10rem"
                >
                    <li v-for="(item, index) in applications" :key="index" @click="handleApplicationDropdown(item)">
                        {{ item }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="clinical-attendance-history-table">
            <div class="attendance-history-header">
                <span class="dark-text weight-600">Feeling</span>
                <span class="dark-text weight-600">Reason</span>
                <span class="dark-text weight-600">Date</span>
                <span class="dark-text weight-600">Note</span>
            </div>
            <div v-if="row_data.length" class="attendance-history-data">
                <div v-for="data in row_data" :key="data.id" class="attendance-history-data-item mood-history-row">
                    <span v-if="data.emoticons.length" v-text="data.emoticons[0]"></span>
                    <span
                        class="my-1 flex flex-wrap gap-1 px-3 w-70"
                        :style="{
                            background: data.reasons.length ? reasonColors(data.category)[0] : '',
                            border: data.reasons.length ? `1px solid ${reasonColors(data.category)[1]}` : '',
                            'border-radius': '10px',
                        }"
                    >
                        <div v-for="(item, idx) in data.reasons" :key="idx" style="color: #000000; font-weight: 600">
                            {{ item }} {{ idx != data.reasons.length - 1 ? ',' : '' }}
                        </div>
                    </span>
                    <span v-text="formattedDate(data.created_at)"></span>
                    <span v-text="data.notes"></span>
                </div>
            </div>
            <div v-else class="bg-white w-100">
                <div class="d-grid justify-content-center align-items-center py-5" style="min-height: 50px">
                    <h5 style="color: #979699">No History Available</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DateRangeSelector from '../../vitals/DateRangeSelector.vue';
    import dayjs from '@/util/dayjs';
    import { clients } from '@/util/apiRequests';

    export default {
        name: 'MoodHistory',
        components: { DateRangeSelector },
        props: {
            client_id: {
                type: Number,
            },
        },
        data() {
            return {
                showApplicationDropdown: false,
                row_data: [],
                selectedOption: 'Weekly',
                selectedApplication: 'STAR Kids',
                applications: ['STAR Kids', 'Other'],
                startDate: null,
                endDate: null,
            };
        },
        methods: {
            async getMoodHistory() {
                try {
                    const body = {
                        type: 'Custom',
                        startDate: this.startDate,
                        endDate: this.endDate,
                    };

                    const endpoint = clients.getMoodHistory(this.selectedApplication, this.client_id);
                    const response = await this.$api.post(endpoint, body);

                    const data = response.data.message.reduceRight((acc, entry) => {
                        for (let date in entry) {
                            if (entry[date].length) {
                                acc = acc.concat(entry[date]);
                            }
                        }
                        return acc;
                    }, []);

                    this.row_data = data;
                } catch (err) {
                    this.$toasted.error(err ?? 'Failed to fetch Mood History details');
                }
            },
            reasonColors(category) {
                if (category === 'pleasant') return ['#aac832', '#E4E9D7'];
                if (category === 'negative') return ['#cb177d', '#FFD9C9'];
                return ['#f58220', '#E4E9D7'];
            },
            formattedDate(date) {
                // return date ? dayjs(date).format('MM/DD/YYYY hh:mm A') : '';
                return date ? dayjs(date).format('MM/DD/YYYY') : '';
            },
            handleApplicationDropdown(val) {
                if (val !== this.selectedApplication) {
                    this.selectedApplication = val;
                    this.getMoodHistory();
                }
                this.showApplicationDropdown = false;
            },
            async handleHistoryPeriod({ period, customStartDate, customEndDate }) {
                if (period === 'Custom Date' && !customStartDate && !customEndDate) return;

                let endDate = dayjs(new Date()).format('YYYY-MM-DD'),
                    startDate = null;

                if (period === 'Weekly') {
                    startDate = dayjs(endDate).utc().subtract(6, 'day').format('YYYY-MM-DD');
                } else if (period === 'Monthly') {
                    startDate = dayjs(endDate).utc().subtract(29, 'day').format('YYYY-MM-DD');
                } else if (period == 'Custom Date') {
                    startDate = dayjs(customStartDate).format('YYYY-MM-DD');
                    endDate = dayjs(customEndDate).format('YYYY-MM-DD');
                }

                if (this.startDate !== startDate || this.endDate !== endDate) {
                    this.selectedOption = period;
                    this.startDate = startDate;
                    this.endDate = endDate;
                    await this.getMoodHistory();
                }
            },
            handleClickOutside(event) {
                const applicationDropdownContainer = this.$refs.applicationDropdownContainer;
                if (applicationDropdownContainer && !applicationDropdownContainer.contains(event.target)) {
                    this.showApplicationDropdown = false;
                }
            },
        },
        async created() {
            if (!Number(process.env.VUE_APP_mobex_plus_company_id) === this.$store.state.user.company_id) {
                this.selectedApplication = 'Other';
                this.applications = this.applications.filter((application) => application !== 'STAR Kids');
            }

            // Set default date
            this.endDate = dayjs(new Date()).format('YYYY-MM-DD');
            this.startDate = dayjs(this.endDate).utc().subtract(6, 'day').format('YYYY-MM-DD');

            await this.getMoodHistory();
        },
        mounted() {
            document.addEventListener('click', this.handleClickOutside);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleClickOutside);
        },
    };
</script>

<style scoped>
    .color-indicator {
        min-width: 15px;
        min-height: 15px;
        border-radius: 50%;
        background-color: red;
    }

    .dropdown-btn,
    .dropdown-btn:focus {
        border-radius: 20px;
        border: 1px solid #adaeb1;
        color: #979699;
        font-weight: 600;
        font-size: 1rem;
        min-width: 8rem;
    }

    .dropdown-container {
        position: absolute;
        background-color: white;
        font-weight: 600;
        color: #979699;
        font-size: 13px;
        padding-top: 10px;
        border-radius: 10px;
        width: 80px;
        cursor: pointer;
        box-shadow: 0px 1px 6px rgba(58, 64, 82, 0.15);
        border-radius: 5px;
        overflow: hidden;
        list-style: none;
    }
</style>
